import * as React from 'react';
import logolight from '../imgs/1.png';

import { styled } from '@mui/material/styles';
import { Box, AppBar as AppBar2, Toolbar, Container, Typography, MenuItem, Menu, useMediaQuery, IconButton, Drawer, List, ListItem } from '@mui/material';
import Button from './Button';
import { Outlet, Link, NavLink } from 'react-router-dom';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';

const logoStyle = {
    width: '200px',
    height: 'auto',
    cursor: 'pointer',
};

const NavMenu = styled((props) => (
    <Menu {...props} />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 5,
        backgroundColor: 'rgb(23, 88, 115, 0.6)',
        minWidth: 100
    }
}));

export default function AppBar({ mode, toggleColorMode }) {

    const isMobileMode = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileAnchorEl, setMobileAnchorEl] = React.useState(false);

    const open = Boolean(anchorEl);

    const mobileDrawer = state => () => {
        setMobileAnchorEl(state);
    }

    const handleClick = (event) => {
      setAnchorEl(event.relatedTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <div>
            <AppBar2 position='fixed' sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}>
                <Container maxWidth='lg'>
                    <Toolbar
                        variant='regular'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '5px',
                            bgcolor:
                                mode === 'light'
                                ? 'rgba(255, 255, 255, 0.2)'
                                : 'rgba(0, 0, 0, 0.1)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            py: '8px',
                            boxShadow:
                                mode ==='light'
                                ? '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)'
                                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                            
                        }}>
                        <Link to='/'><img src={logolight} style={logoStyle} /></Link>
                            {isMobileMode ? (
                                <>
                                <IconButton sx={{ color: 'white'}} onClick={mobileDrawer(true)}>
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    open={mobileAnchorEl}
                                    onClose={mobileDrawer(false)}
                                    anchor='right'
                                    PaperProps={{ sx: { width: '50vw', backgroundColor: 'primary.light', color: 'white' }}}
                                >
                                        <MenuItem>
                                            <Link to='/about' style={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }} onClick={mobileDrawer(false)}><Typography variant='body3' color='text.light'>About</Typography></Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to='/shippers' style={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }} onClick={mobileDrawer(false)}><Typography variant='body3' color='text.light'>Shippers</Typography></Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Typography variant='body3' color='text.light'>Carriers</Typography>
                                        </MenuItem>
                                        <MenuItem>
                                            <Typography variant='body3' color='text.light'>G14 Classified</Typography>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to='/contact' style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', color: 'white' }} onClick={mobileDrawer(false)}><Typography variant='body3' color='text.light'>Contact</Typography></Link>
                                        </MenuItem>
                                    </Drawer>
                                </>
                            ) :
                                (<>
                                        <Box sx={{ flexGrow: 1, display :'flex', alignItems: 'center', ml: '-18px', px: 2 }}>
                                        <Box sx={{ display: { xs: 'none', md: 'flex' }, px: 1, pl: 5 }}>
                                        <NavLink to='/about' style={{ textDecoration: 'none', color: 'white'}}  className= { ({ isActive }) => isActive ? 'activeLink' : ''}>
                                            <MenuItem sx={{ py: '6px', px: '12px' }}>
                                               <Typography variant='body3' color='text.light'>About</Typography>
                                            </MenuItem>
                                        </NavLink>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                            <NavLink to='/shippers' style={{ textDecoration: 'none', color: 'white'}} className= { ({ isActive }) => isActive ? 'activeLink' : ''}>
                                                <MenuItem sx={{ py: '6px', px: '12px'}}>
                                                        <Typography variant='body3' >Shippers<ArrowDropDownIcon sx={{ p: 0, height: '15px' }} onMouseOver={(e) => handleClick(e)}/></Typography>
                                                </MenuItem>
                                            </NavLink>
                                            <NavMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                                                    <MenuItem sx={{ color: 'white' }} onClick={handleClose}>By Mode</MenuItem>
                                                    <MenuItem sx={{ color: 'white' }} onClick={handleClose}>Visibility</MenuItem>
                                                    <MenuItem sx={{ color: 'white' }} onClick={handleClose}>Cross-Border</MenuItem>
                                                    <MenuItem sx={{ color: 'white' }} onClick={handleClose}>Project Logistics</MenuItem>
                                            </NavMenu>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                            <MenuItem sx={{ py: '6px', px: '12px'}}>
                                                <Typography variant='body' color='text.light'>Carriers</Typography>
                                            </MenuItem>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                            <MenuItem sx={{ py: '6px', px: '12px'}}>
                                                <Typography variant='body' color='text.light'>G14 Classified</Typography>
                                            </MenuItem>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                            <NavLink to='/contact' style={{ textDecoration: 'none', color: 'white'}}  className= { ({ isActive }) => isActive ? 'activeLink' : ''}>
                                                <MenuItem sx={{ py: '6px', px: '12px'}}>
                                                    <Typography variant='body' color='text.light'>Contact</Typography>
                                                </MenuItem>
                                            </NavLink>
                                        </Box>
                                        </Box>
                                    </>
                                    )
                                 }
                                 {isMobileMode ? (<></>) : (<Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                        <Button variant="contained" size="small" component="a" target="_blank" sx={{ py: '6px', px: '20px' }} >
                                            <PhoneInTalkIcon sx={{ pr: 1 }} />773.916.9330
                                        </Button>
                                    </Box>)}
                        </Toolbar>

                </Container>
            </AppBar2>
        </div>

    );
}