export const servicesSuite = [
    {
        id: 0,
        title: 'Truckload / Partial TL',
        desc: 'Our truckload and partial truckload solutions in the Dry Van and Reefer worlds are unrivaled. With our robust network and years of experience, no solutions is too big nor too small to handle.',
        img: require('../imgs/contractsign.jpg'),
        imgalt: 'Contracting Signing'
    },
    {
        id: 1,
        title: 'Capacity Solutions',
        desc: 'With a full range of reliable solutions, our brokerage and independent contractors help you move faster and more efficiently. With us you can move any load, any time, anywhere. From Drayage to Expedited - our experts are here to help.',
        img: require('../imgs/247.jpg'),
        imgalt: '24/7 Sign'
    },
    {
        id: 2,
        title: 'Project Logistics',
        desc: 'Your critical and complex shipping needs get taken care of with Lakeside. We know how important details are, and some times you needed that freight yesterday.',
        img: require('../imgs/contactpoint.jpg'),
        imgalt: 'Working'
    },
    {
        id: 3,
        title: 'Visibility & Reporting',
        desc: 'With automation, data analytics, and technology, Lakeside provides you with actionable insights and reporting so you can make better decisions for your supply chain. You get the piece of mind knowing where your shipment is always at.',
        img: require('../imgs/cnetwork.jpg'),
        imgalt: 'Group of Trucks'
    }
];