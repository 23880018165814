import * as React from 'react';
import { Typography, Card, CardContent, CardMedia, CardActions, Grid } from '@mui/material';

const cardText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 'clamp(1rem, 10vw, 1rem)',
    color: 'black',
    pb: 1
};

const cardStyle ={ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' };

export function Cards(props) {
    return(
        <Grid item sx={{ display: 'flex' }} xs={6} md={3}>
            <Card sx={cardStyle}>
                <CardMedia component='img' height='140' image={props.img} alt="random" />
                <CardContent>
                    <Typography variant="body1" sx={cardText}>
                        {props.children}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
};