import * as React from 'react';
import { Button as Button2 } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const button_theme = createTheme({
    palette: {
        primary: {
             main: '#64ee85',
        },
    },
});

export default function Button(props) {
    return(
        <ThemeProvider theme={button_theme}>
            <Button2 {...props} />
        </ThemeProvider>
    );
}