export const modes_tabs = [
{
    id: 0,
    label: 'Full Truckload',
    short_label: 'Truckload',
    header: 'From Dry Van to Flatbed',
    title: 'Dynamic Truckload Solutions',
    desc: 'We are a full service provider of everything truckload. Our robust network of well-vetted carriers connects you to thousands of trucks and trailers across the United States and Canada for all your domestic and cross-border needs.',
    image: require('../imgs/dryvan.jpg')
},
{
    id: 1,
    label: 'Partial TL',
    short_label: 'Partials',
    header: 'LTL, Volume, and Partial Truckloads',
    title: 'Small or Mid-Size, Access Cost Savings',
    desc: 'With our wealth of knowledge and extensive network, Lakeside is a trusted provider to help get you cost savings and simplify your partial shipments on Dry Vans, Flatbeds, and Reefers.',
    image: require('../imgs/redtruck.jpg')
},
{
    id: 2,
    label: 'Temperature Controlled',
    short_label: 'Reefer',
    header: 'Perishables and Frozen',
    title: 'Shelf-Life Matters',
    desc: 'Frozen, refrigerated, or ambient - we are experts at moving temp controlled freight. From Full Truckload to Refrigerated LTL - Lakeside has the steps in place to ensure your freight arrives on-time, in-tact, and at-temp.',
    image: require('../imgs/Reefer.jpg')
},
{
    id: 3,
    label: 'Specialized',
    short_label: 'Specialized',
    header: 'From White-Glove to Over-Dim',
    title: 'Meticulous with Details',
    desc: 'Your expedited load needed to be there yesterday, and your over-dimensional load has tons of details. We offer safe, efficient, and organized coverage for all of your critical shipments.',
    image: require('../imgs/specialized.jpg')
},
{
    id: 4,
    label: 'Drayage',
    short_label: 'Drayage',
    header: 'Ports and Railyards',
    title: 'Drayage Simplified',
    desc: 'Whether it is at the port, the railyard, or a dock - we arrange drayage services in most markets with competitive pricing. With the right network and systems in place backed by our experience, you never have to worry about your shipments.',
    image: require('../imgs/port.png')
},
];