import * as React from 'react';
import { Typography, Card, CardContent, CardMedia, Box, Grid, Divider } from '@mui/material';

const cardText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 'clamp(1rem, 10vw, 1rem)',
    color: 'black',
    py: 1
};

const cardTextRight = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    fontSize: 'clamp(1rem, 10vw, 1rem)',
    color: 'black',
    py: 1
};

const cardStyle = { display: 'flex', flexDirection: 'column', width: '100%' };
const cardStyleRight = { display: 'flex' };

export function Cards(props) {

    if (props.orientation === 'right') {
        return(
            <Card sx={cardStyleRight} {...props}>
            <Box sx={{ display: 'flex', flexDirection: 'column', pr: 2, pl: 2 }}>
            <CardContent  sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5" sx={{ pb: 1, fontWeight: 'bold', fontSize: 20 }}>
                    {props.cardTitle}
                </Typography>
                <Divider sx={{ backgroundColor: 'secondary.main', width: '100%', height: '3px', borderRadius: 5 }} orientation='vertical' flexItem />
                <Typography variant="body1" sx={cardTextRight}>
                    {props.children}
                </Typography>
            </CardContent>
            </Box>
            <CardMedia component='img' height='120' image={props.img} sx={{ objectFit: 'contain' }} alt={props.imgalt} />
        </Card>
        )
    } else {

        return(
            <Grid item sx={{ display: 'flex' }} xs={6} md={3}>
                <Card sx={cardStyle} {...props}>
                    <CardMedia component='img' height='140' image={props.img} alt={props.imgalt} />
                    <CardContent>
                        <Typography variant="h5" sx={{ pb: 1, fontWeight: 'bold', fontSize: 20 }}>
                            {props.cardTitle}
                        </Typography>
                        <Divider sx={{ backgroundColor: 'secondary.main', width: '100%', height: '3px', borderRadius: 5 }} orientation='vertical' flexItem />
                        <Typography variant="body1" sx={cardText}>
                            {props.children}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
};