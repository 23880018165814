import * as React from 'react';
import { Box, Container, Stack } from '@mui/material';

export function Hero(props) {
    return(
        <Box
            id={'hero.' + props.id }
            sx={{
                backgroundImage: `url(${props.bgImage})`,
                width: '100%',
                height: '70vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 10 }}}>
                <Stack spacing={4} useFlexGap sx={{ height: '42vh', width: { xs: '100%', sm: '70%' }, alignItems: 'center', justifyContent: 'center' }}>
                    {props.children}
                </Stack>
            </Container>
        </Box>
    );
}