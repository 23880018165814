import * as React from 'react';
import background from '../../imgs/officeteam.jpg';
import { Box, Container, Typography, Divider, Stack } from '@mui/material';
import MobileContext from '../../MobileProvider';

import { Hero } from '../../components/Hero';
import { BodyText } from '../../components/helpers';
import LifetimeStats from '../../components/LifetimeStats';

import tiaColor from '../../imgs/tia-color.png';
import tiaBondColor from '../../imgs/tiabond-color.png';
import inc5000Color from '../../imgs/inc5000-color.png';
import smartwayColor from '../../imgs/smartway-color.png';

const statsText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 'clamp(1rem, 10vw, 2rem)',
    color: 'primary.main'
}

const logosMobile = {
    width: 200,
    height: 'auto',
}

const logosAll = {
    height: 120
}

export default function About() {
    const isMobileMode = React.useContext(MobileContext);

    return(
        <div>
        <Hero id="about" bgImage={background} />
        <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }} >
            <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 2, sm: 2 }}} >
                <BodyText align='left'>
                    At Lakeside, we live and breath logistics. It all starts with at our family's roots trucking since the 1950s. The team also comes from major brokerage environments and they've taken the best practices from those experiences to build an unparalled service that adapts to your needs. True 24/7 players, we know how critical service is so we're always here, making sure we always have your back.<br /> <br />
                    We are proud to be powered by Freight-Tec, a top 100 broker recognized by Inc. magazine, which has been a leader in the industry since 1985. Additionally, Freight-Tec is 1 of only 28 brokers nationwide that carries a $100K Guaranteed Payment Bond, providing our customers with security and confidence in every transaction. <br /><br />
                    What sets Lakeside apart is our commitment to making things easy for everyone.<br /><br />
                    We don’t run you through multiple representatives like most providers. You work with a dedicated team that knows your business in-and-out, providing you with the type of service that builds strong partnerships. Even with the best technology in place, we go the extra mile by running thorough compliance checks every 90 days to protect you from fraud.<br /><br />
                    We specialize in handling truckload and partial shipments, with a focus on temperature-controlled freight. From perishables to frozen items, we know how crucial it is to maintain shelf life and get your products where they need to be — on time and in-tact. When it comes to special shipments - expedited deliveries, airport pickups, or anything requiring lift-gates or sprinter vans - we know how to move fast and ensure every detail is taken care of.<br /><br />
                    Lakeside is more than just a transportation provider. We are your dedicated partner, committed to delivering secure, efficient, and reliable services every step of the way.
                </BodyText>
            </Container>
        </Box>
        <Container sx={{ backgroundColor: 'white', borderRadius: 5, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 } }} >
            <Stack spacing={6} direction={{ xs: 'column', sm: 'row', justifyContent: 'center' }} useFlexGap sx={{ pb: { xs: 2.5, sm: 5 } }}>
                    <img src={tiaColor} style={isMobileMode ? logosMobile : logosAll } />
                    <img src={inc5000Color} style={isMobileMode ? logosMobile : logosAll } />
                    <img src={smartwayColor} style={isMobileMode ? logosMobile : logosAll } />
                    <img src={tiaBondColor} style={isMobileMode ? logosMobile : logosAll } />
            </Stack>
            <Divider sx={{ backgroundColor: 'primary.main', width: '100%', height: '3px', borderRadius: 5, mb: 5 }} orientation='vertical' flexItem />
            <LifetimeStats />
        </Container>
    </div>
    );
}