import * as React from 'react';
import background from '../../imgs/716.jpg';
import { Box, Container, Typography, TextField, Button, Grid } from '@mui/material';

import { Hero } from '../../components/Hero';
import { BodyText } from '../../components/helpers';

export default function Contact() {
    return(
        <div>
        <Hero id="contact" bgImage={background} />
        <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }} >
        <Container sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
        <Grid container sx={{ width: '100%' }} spacing={4}>
            <Grid item xs={6} md={4}>
                <BodyText align='left' fontSize={22}>
                    Contact us now!
                </BodyText>
            </Grid>
            <Grid item xs={6} md={8}>
                <form>
                    <TextField label='Company' variant='filled' fullWidth margin='normal' />
                    <TextField label='Contact Name' variant='filled' fullWidth margin='normal' />
                    <TextField label='Email' variant='filled' fullWidth margin='normal' />
                    <TextField label='Request' variant='filled' multiline rows={8} fullWidth margin='normal' />
                </form>
            </Grid>
            </Grid>
        </Container>
        </Box>
    </div>
    );
}