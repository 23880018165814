import * as React from 'react';
import background from '../../imgs/officeteam.jpg';
import { Box, Container, Typography } from '@mui/material';

import { Hero } from '../../components/Hero';
import { Title, GreenTitle, BodyText } from '../../components/helpers';
import LifetimeStats from '../../components/LifetimeStats';

export default function About() {
    return(
        <div>
        <Hero id="about_us" bgImage={background}>
            <Title size='md' type='h1'>Logistics runs in our blood.</Title>
            <GreenTitle fontStyle='bold'>Learn about what drives us.</GreenTitle>
        </Hero>
        <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }} >
        <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
            <BodyText align='left'>
                At Lakeside, we live and breath logistics. It all starts with at our roots. The team comes from major brokerage environments and they've taken the best practices from those experiences to build true personalized service that adapts to your needs. True 24/7 players, we know how critical service is so we're always here, making sure you're covered no matter what.<br /> <br />
                We are proud to be powered by Freight-Tec, a top 100 broker recognized by Inc. magazine, which has been a leader in the industry since 1985. Additionally, Freight-Tec is 1 of only 28 brokers nationwide that carries a $100K Guaranteed Payment Bond, providing our customers with security and confidence in every transaction. <br /><br />
                What sets Lakeside apart is our commitment to making things easy for everyone.<br /><br />
                We don’t push you through multiple representatives like most providers. You work with a dedicated team that knows your business to a T, providing you service that builds long-term partnerships. Even cutting edge tech in place, we go the extra mile by running thorough compliance checks every 90 days to protect you from fraud.<br /><br />
                We specialize in handling truckload and partial shipments, with a focus on temperature-controlled freight. From perishables to frozen items, we know how crucial it is to maintain shelf life and get your products where they need to be — on time and in-tact. When it comes to special shipments - expedited deliveries, airport pickups, or anything requiring lift-gates or sprinter vans - we know how to move fast and ensure every detail is taken care of.<br /><br />
                Lakeside is more than just a transportation provider. We are your dedicated partner, committed to delivering secure, efficient, and reliable services every step of the way.
            </BodyText>
        </Container>
        </Box>
        <LifetimeStats />
    </div>
    );
}