import { ThemeOptions, createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#193046',
      light: '#175873',
    },
    secondary: {
      main: '#23c54b',
      light: '#64ee85',
      dark: '#469d32',
    },
  },
  typography: {
    fontSize: 15,
    fontWeightRegular: true,
    fontFamily: [
      'Figtree',
      'Roboto',
      'Helvetica Neue'
    ].join(','),
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&:hover:not(.Mui-disabled)::before': {
            borderBottomColor: '#64ee85',
            borderRadius: 5,
            
          },
          '&:after': {
            borderBottomColor: '#64ee85',
            borderRadius: 5
          }
        }
      }
    }
  }
});