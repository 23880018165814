import './App.css';
import AppBar from './components/AppBar';
import Homepage from './pages';
import About from './pages/about'
import Shippers from './pages/shippers';
import Footer from './components/Footer';
import Contact from './pages/contact';

import MobileContext from './MobileProvider';
import { useMediaQuery } from '@mui/material';

import { Route, Routes } from 'react-router-dom';

function App() {
  const isMobileMode = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <MobileContext.Provider value={isMobileMode}>
      <AppBar mode='light' />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/shippers" element={<Shippers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </MobileContext.Provider>
  );
}

export default App;
