// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #0a3042;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiBox-root .MuiMenuItem-root:hover {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid #64ee85;
}

.middleSlogan {
  text-shadow: 5px #fff, 5px #fff;
}

.activeLink {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid #64ee85;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,gCAAgC;AAClC","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #0a3042;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.MuiBox-root .MuiMenuItem-root:hover {\n  border-radius: 0px;\n  background-color: rgba(0, 0, 0, 0);\n  border-bottom: 2px solid #64ee85;\n}\n\n.middleSlogan {\n  text-shadow: 5px #fff, 5px #fff;\n}\n\n.activeLink {\n  border-radius: 0px;\n  background-color: rgba(0, 0, 0, 0);\n  border-bottom: 2px solid #64ee85;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
