import * as React from 'react';
import background from '../../imgs/shippers.jpg';

import { Box, Container, Stack, Grid } from '@mui/material';

import { Hero } from '../../components/Hero';
import { Title, GreenTitle } from '../../components/helpers';
import { Cards } from '../../components/Cards';
import LifetimeStats from '../../components/LifetimeStats';

import a247 from '../../imgs/247.jpg';
import contactpoint from '../../imgs/contactpoint.jpg';
import contractsign from '../../imgs/contractsign.jpg';
import cnetwork from '../../imgs/cnetwork.jpg';

export default function Shippers() {
    return(
        <div>
        <Hero id='solutions' bgImage={background}>
            <Title size='md' type='h1'>Delivering an unparalled logistics experience.</Title>
            <GreenTitle fontStyle='bold'>We bring together the traditional and digital worlds, redefining the meaning of premium service.</GreenTitle>
        </Hero>
        <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }}>
            <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}}>
                <Title size='md' type='h2'>Our Suite of Services</Title>
                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 } }}>
                    <Grid container variant='contained' alignItems={'stretch'} spacing={2}>
                        <Cards img={contractsign}>
                            Project Logistics - 
                        </Cards>
                        <Cards img={a247}>
                            Capacity Solutions -
                        </Cards>
                        <Cards img={contactpoint}>
                            White Glove -
                        </Cards>
                        <Cards img={cnetwork}>
                            Visibility - 
                        </Cards>
                    </Grid>
                </Stack>
            </Container>
         </Box>
         <LifetimeStats />
        </div>
    );
}