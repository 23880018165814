import * as React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Box, Container, Link, Stack, Typography, Divider, Grid } from '@mui/material';

import { Hero } from '../components/Hero';
import { Title, GreenTitle, BodyText } from '../components/helpers';
import { Cards } from '../components/Cards';
import LifetimeStats from '../components/LifetimeStats';

import background from '../imgs/716.jpg';
import a247 from '../imgs/247.jpg';
import contactpoint from '../imgs/contactpoint.jpg';
import contractsign from '../imgs/contractsign.jpg';
import cnetwork from '../imgs/cnetwork.jpg';
import freighttec from '../imgs/freight-tec.png';
import pallets from '../imgs/pallets.jpg';
import produce from '../imgs/produce.jpg';
import sprintervan from '../imgs/sprintervan.jpg';

export default function Homepage() {
    return(
        <div>
        <Hero id='home' bgImage={background}>
            <Title size='md' type='h1'>Dependable. Experienced. Constant.</Title>
            <GreenTitle fontStyle='bold'>Logistics is our life.</GreenTitle>
        </Hero>
      <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }}>
      <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
              <BodyText align='center'>
                Our experience in the transportation industry is unprecedented. We come from the big box brokerage environments and have meshed together all the best practices to provide you with true personalized transportation services that meet your needs 24/7. We're powered by Freight-Tec, who is ranked a top 100 broker by "Inc." magazine that has been in business since 1985. We are 1 of only 28 brokers that carries a $100K Guaranteed Payment Bond.
              </BodyText>
            <Divider sx={{ backgroundColor: 'secondary.light', width: '100%', height: '2px', mb: 5 }} orientation='vertical' flexItem />
            <Title size='md' type='h2'>Why Lakeside?</Title>
            <GreenTitle fontStyle='italic'>The Lakeside Advantage</GreenTitle>
            <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 } }}>
                <Grid container variant='contained' alignItems={'stretch'} spacing={2}>
                    <Cards img={contractsign}>
                        Carriers and Customers get on-boarded with ease, not having to drop money on technology. We move fast and get you setup same day.
                    </Cards>
                    <Cards img={a247}>
                        Always available 24/7. Rain or shine, even on the holidays - you never have to hesitate to pick up your phone. We have your back no matter what!
                    </Cards>
                    <Cards img={contactpoint}>
                        No more cycling through representatives, which means we know your business better then anyone else. You get true personalized service.
                    </Cards>
                    <Cards img={cnetwork}>
                        Even with the best technology in place, compliance runs through all partners every 90 days to ensure security and information. Minimizing risks to fraud.
                    </Cards>
                </Grid>
            </Stack>
            <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 } }}>
                <Grid container variant='contained' alignItems={'stretch'} spacing={2}>
                    <Cards img={freighttec}>
                        We're powered with the best by Freight-Tec. Freight-Tec moves thousands of loads a month, and is TIA certified as a platinum ranked broker.
                    </Cards>
                    <Cards img={pallets}>
                        Partial shipments is the bread and butter. Whether it's dry or temperature controlled freight, our robust network is reliable to get it there.
                    </Cards>
                    <Cards img={produce}>
                        Perishables or frozen items, we're temp control brokers you can trust. We know how critical shelf-life is for your products, making sure they get there on time. 
                    </Cards>
                    <Cards img={sprintervan}>
                        Special shipments are no problem. Expedited, airport pickups, lift-gates, sprinter vans - we know that details are key and that it needs to move fast.
                    </Cards>
                </Grid>
             </Stack>
        </Container>
    </Box>
    <LifetimeStats />
  </div>
    );
}