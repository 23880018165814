export const heros = [
    {
        id: '0',
        name: 'home',
        title: 'Provider of unparalled logistics services',
        header: 'We bring together the traditional and digital worlds, redefining the meaning of premium service.'
    },
    {
        id: '1',
        name: 'about',
        title: 'Logistics runs in our blood.',
        header: 'Learn about what drives us.'
    },
    {
        id: '2',
        name: 'shippers',
        title: 'Secure. Efficient. Reliable.',
        header: 'Logistics is our life.'
    },
    {
        id: '3',
        name: 'contact',
        title: 'Drop us a line.',
        header: 'We are available 24/7, including Holidays.'
    },
]