import * as React from 'react';
import { Typography } from '@mui/material';

export function Title(props) {

    let fontColor = '';

    if (props.fontColor) {
        fontColor = props.fontColor
    } else {
        fontColor = 'white'
    }


    return(
        <Typography
            variant={props.type}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 
                    props.size === 'md' ? 'clamp(1rem, 10vw, 2.75rem)' : props.size === 'sm' ? 'clamp(1rem, 10vw, 1.55rem)' : 'clamp(1rem, 10vw, 3.15rem)',
                color: fontColor
            }}
            {...props}
        >
            {props.children}
        </Typography>
    )
}

export function GreenTitle(props) {
    let fontStyle = '';

    if (props.fontStyle === 'italic') {
        fontStyle = { fontStyle: 'italic' }
    } else if (props.fontStyle === 'bold') {
        fontStyle = { fontWeight: 'bold' }
    } else {
        fontStyle = { fontStyle: 'normal' }
    }

    return(
        <Typography
            className='middleSlogan'
            variant="h2"
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                alignContent: 'center',
                textAlign: 'center',
                fontStyle,
                fontSize: 'clamp(1.5rem, 10vw, 1.75rem)',
                color: 'secondary.light'
            }}
        >
        {props.children}
    </Typography>
    )
}

export function BodyText(props) {
    return(
        <Typography
            variant="body1"
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: props.align,
                /* fontWeight: 'bold', */
                fontSize: props.fontSize,
                color: 'white',
                pb: { xs: 2.5, sm: 5 }
            }}
        >
            {props.children}
        </Typography>
    )
}