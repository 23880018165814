import * as React from 'react';
import { Box, Typography, Tab, Tabs, Container, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileContext from '../MobileProvider';

import { modes_tabs } from '../tabs/mode';

import PropTypes from 'prop-types';

const statsText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 'clamp(1rem, 10vw, 2rem)',
    color: 'primary.main'
}

const imgStyles = {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    objectPosition: 'center',
}

function TabPanel (props) {
    const { children, value, image, index, ...other } = props;

    return(
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Grid container sx={{ width: '100%', boxShadow: 5, borderRadius: '5px' }} spacing={0}>
                <Grid item xs={6} md={8} sx={{ px: 3, pt: 2 }}>
                <Typography variant="h4" sx={{ pb: 1, fontSize: 16, color: 'primary.main' }}>
                        {props.header}
                    </Typography>
                    <Typography variant="h5" sx={{ pb: 1, fontWeight: 'bold', fontSize: 28, color: 'primary.dark' }}>
                        {props.title}
                    </Typography>
                    <Typography sx={{ pb: 3 }}>{props.body}</Typography>
                </Grid>
                <Grid item xs={6} md={4}><img src={image} style={imgStyles} /></Grid>
            </Grid>
        )}
      </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function ByMode() {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const isMobileMode = React.useContext(MobileContext);


    const tabChange = (event, newValue) => { setValue(newValue); };

    const tabs_length = modes_tabs.length;

    const tabs_header = modes_tabs.map((modes, index) => (
         <Tab label={modes.label} sx={{ fontWeight: 'bold', fontSize: 16, color: 'primary.main' }} />
    ));

    console.log("tab numbers: " + tabs_length);

    return(
        <Box sx={{ backgroundColor: 'white', width: '100%' }}>
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 5, sm: 6 }, width: '100%'}} >
            <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={tabChange}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant={isMobileMode ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{ bgcolor: 'white', pl: 2, pr: 2, pb: 1,  }}
                    aria-label="scrollable force tabs example"
                    TabIndicatorProps={{
                        sx: {
                            height: 5,
                            borderRadius: 5
                        }
                     }}
                >
                    {tabs_header}
                </Tabs>
            {modes_tabs.map((content) => (
                <TabPanel value={value} image={content.image} index={content.id} dir={theme.direction} body={content.desc} title={content.title} header={content.header} {...a11yProps(value)} />
            ))}
            </Box>
            </Container>
        </Box>
    )
}