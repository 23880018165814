import './App.css';
import AppBar from './components/AppBar';
import Homepage from './pages';
import About from './pages/about'
import Shippers from './pages/shippers';
import Footer from './components/Footer';
import Contact from './pages/contact';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <AppBar mode='light' />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/shippers" element={<Shippers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
