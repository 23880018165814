import * as React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { GreenTitle, Title } from './helpers';
import { heros } from '../tabs/hero_desc';

export function Hero(props) {

    const filter = heros.filter((e) => e.name === props.id);

    return(
        <Box
            id={'hero.' + props.id }
            sx={{
                backgroundImage: `url(${props.bgImage})`,
                width: '100%',
                height: '65vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 10 }}}>
                <Stack spacing={4} useFlexGap sx={{ height: '42vh', width: { xs: '100%', sm: '70%' }, alignItems: 'center', justifyContent: 'center' }}>
                <Title size='md' type='h1'>{filter[0].title}</Title>
                <GreenTitle fontStyle='bold'>{filter[0].header}</GreenTitle>
                </Stack>
            </Container>
        </Box>
    );
}