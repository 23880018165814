import * as React from 'react';
import { Box, Container, Stack, Typography, Divider } from '@mui/material';
import CountUp from 'react-countup';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const statsText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 'clamp(1rem, 10vw, 2rem)',
    color: 'primary.main'
}

export default function LifetimeStats() {
    return(
        <Box sx={{ backgroundColor: 'white' }}>
            <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
                <Typography
                variant="h2"
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignSelf: 'center',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    fontSize: 'clamp(1rem, 10vw, 1.75rem)',
                    color: 'primary.main'
                }}>
                    Lifetime Stats
                </Typography>
                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 } }}>
                    <Typography variant="h2" sx={statsText}>
                        <WarehouseIcon sx={{ fontSize: '96px', alignSelf: { xs: 'center', sm: 'auto' } }} />
                        <CountUp prefix="Over " suffix="+ shippers served" end={1000} />
                    </Typography>
                    <Divider sx={{ backgroundColor: 'primary.dark', height: { xs: '2px', sm: '12vh' }, width: { xs: '100%', sm: '2px' } }} orientation='horizontal' flexItem />
                    <Typography variant="h2" sx={statsText}>
                        <LocalShippingIcon sx={{ fontSize: '96px', alignSelf: { xs: 'center', sm: 'auto' } }} />
                        Over 5 million miles moved
                    </Typography>
                    <Divider sx={{ backgroundColor: 'primary.dark', height: { xs: '2px', sm: '12vh' }, width: { xs: '100%', sm: '2px' } }} orientation='horizontal' flexItem />
                    <Typography variant="h2" sx={statsText}>
                        <LightbulbIcon sx={{ fontSize: '96px', alignSelf: { xs: 'center', sm: 'auto' }}} />
                        30+ years of experience
                    </Typography>
                </Stack>
            </Container>
        </Box>
    )
}