export const advantages = [
    {
        row1: [
            {
                id: 'onboarding',
                title: 'Easy On-boarding',
                desc: 'Carriers and Customers get on-boarded with ease, not having to drop money on technology. We move fast and get you setup same day.',
                img: require('../imgs/contractsign.jpg'),
                imgalt: 'Contracting Signing'
            },
            {
                id: '247',
                title: 'Always Available',
                desc: 'Get ahold of us 24/7. Rain or shine, even on the holidays - you never have to hesitate to pick up your phone. We have your back no matter what!',
                img: require('../imgs/247.jpg'),
                imgalt: '24/7 Sign'
            },
            {
                id: 'dedicated',
                title: 'Unwavered Dedication',
                desc: 'No more cycling through representatives, which means we know your business better then anyone else. You get true personalized service.',
                img: require('../imgs/contactpoint.jpg'),
                imgalt: 'Working'
            },
            {
                id: 'technology',
                title: 'Cutting Edge Tech',
                desc: 'Even with the best technology in place, compliance runs through all partners every 90 days to ensure security and information. Minimizing risks to fraud.',
                img: require('../imgs/cnetwork.jpg'),
                imgalt: 'Group of Trucks'
            }
        ]
    },
    {
        row2: [
            {
                id: 'support',
                title: 'Back Office Support',
                desc: 'We are powered with the best by Freight-Tec. Freight-Tec moves thousands of loads a month, and is TIA certified as a platinum ranked broker.',
                img: require('../imgs/freight-tec.png'),
                imgalt: 'Freight-Tec Logo'
            },
            {
                id: 'partials',
                title: 'Save on Small TLs',
                desc: 'Partial shipments is the bread and butter. Whether it is dry or temperature controlled freight, our robust network is reliable to get it there.',
                img: require('../imgs/pallets.jpg'),
                imgalt: 'Warehouse Pallets'
            },
            {
                id: 'reefer',
                title: 'Temp Control Options',
                desc: 'No more cycling through representatives, which means we know your business better then anyone else. You get true personalized service.',
                img: require('../imgs/produce.jpg'),
                imgalt: 'Fruits & Produce'
            },
            {
                id: 'specialized',
                title: 'Handled with Care',
                desc: 'Even with the best technology in place, compliance runs through all partners every 90 days to ensure security and information. Minimizing risks to fraud.',
                img: require('../imgs/sprintervan.jpg'),
                imgalt: 'Sprinter Van being loaded'
            }
        ]
    }
];