import * as React from 'react';
import background from '../../imgs/shippers.jpg';
import background_image from '../../imgs/transport_background.png';

import crossBorder from '../../imgs/globe.png';
import partner from '../../imgs/partner.jpg'
import supplychain from '../../imgs/supplychain.jpg'
import pallet from '../../imgs/pallet.png'
import multimodal from '../../imgs/multimodal.png'
import visibility from '../../imgs/visibility.png'
import optimize from '../../imgs/optimize.png'
import expedited from '../../imgs/expedited.png'

import { Box, Container, Stack, Grid, Divider, Card, CardMedia, CardContent, Typography, Paper } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as CrossBorderIcon } from '../../imgs/icons/cross-border.svg';

import { Hero } from '../../components/Hero';
import { Title } from '../../components/helpers';
import { Cards } from '../../components/Cards';
import LifetimeStats from '../../components/LifetimeStats';
import { advantages } from '../../tabs/advantagesCards';
import ByMode from '../../components/ByMode';
import { servicesSuite } from '../../tabs/servicesSuite';

import { shippersCards } from '../../tabs/shippersCards';

const cardText = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignSelf: 'center',
    fontSize: 'clamp(1rem, 10vw, 1rem)',
    color: 'black',
    py: 1
};

const cardStyle ={ display: 'flex' };


export default function Shippers() {


    return(
        <div>
        <Hero id='shippers' bgImage={background} />
        <Box sx={{ width: '100%', backgroundColor: 'primary.dark' }}>
            <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 }, pb: 5, width: '100%' }}>
                    <Typography>
                        <Title size='md' type='h2'>Get a partner, not a provider.</Title>
                        <Typography sx={{ color: 'white', fontSize: 22, pt: 2 }}>Optimally move your freight with a trusted transportation partner. With our large network of well vetted carriers and owner operators, Lakeside provides the ideal capacity to you for your needs. The market is constantly shifting, so we react quickly and responsively to simplify your experience.</Typography>
                    </Typography>
                    <img src={partner} height='250' style={{ borderRadius: 5 }} />
                </Stack>
                <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 }, pb: 5, width: '100%' }}>
                    <img src={supplychain} height='250' style={{ borderRadius: 5 }} />
                    <Typography>
                        <Title size='md' type='h2'>Unlock your supply chain.</Title>
                        <Typography sx={{ color: 'white', fontSize: 22, pt: 2 }}>We provide you with the data and insights on your supply chain so you can make the best decisions and unlock the true potential of your network. Lakeside's comprehensive services package can adapt to the most complex supply chain needs - reducing costs, and streamlining inefficiencies. </Typography>
                    </Typography>
                </Stack>
            </Container>
         </Box>
         <Box sx={{ backgroundImage: `url(${background_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '100%' }}>
            <Container sx={{ backgroundColor: 'white', borderRadius: 5, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }}}>

                {shippersCards.map((arrays, index) => {
                        const cards_array = Object.keys(arrays).map((k) => arrays[k]);
                        const cards_flat = cards_array.flat();
                        const temp_data = cards_flat.map((e) => (
                            <Cards img={e.image} cardTitle={e.title} imgalt={e.alt} orientation='right' elevation={0}>
                                {e.desc}
                            </Cards>
                        ));
                        return(
                            <Grid sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} xs={6} md={3}>
                                    {temp_data}
                            </Grid>
                        );
                        
                })}

                <Divider sx={{ backgroundColor: 'primary.main', width: '100%', height: '3px', borderRadius: 5, mb: 5 }} orientation='vertical' flexItem />
                <Title size='md' type='h2' fontColor='primary.main'>The Bread and Butter.</Title>
                <ByMode />
            </Container>
        </Box>
        </div>
    );
}