import { ThemeOptions, createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#193046',
      light: '#175873',
    },
    secondary: {
      main: '#23c54b',
      light: '#64ee85',
      dark: '#469d32',
    },
  },
  typography: {
    fontSize: 13,
  },
  shape: {
    borderRadius: 5,
  }
});