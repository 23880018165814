export const shippersCards = [ 
    {
        row1: [
            {
                id: 0,
                title: "Partial Truckload",
                short: "Partial TL",
                desc: "Save money and LTL headaches by shipping your medium-sized shipments with our Partial services.",
                image: require('../imgs/pallet.png'),
                alt: "Pallet Icon"
            },
            {
                id: 1,
                title: "Multimodal Solutions",
                short: "Multimodal",
                desc: "From Full Truckload to Intermodal - get the right capacity you need, when you need it, and how you need it.",
                image: require('../imgs/multimodal.png'),
                alt: "Multimodal Icon"
            },
            {
                id: 2,
                title: "Cross-Border Logistics",
                short: "Cross-Border",
                desc: "We have a long history of working with customers on cross-border shipments, we take care of the details.",
                image: require('../imgs/globe.png'),
                alt: "Globe Icon"
            },
        ]
    },
    {
        row2: [
            {
                id: 3,
                title: "Data & Visibility",
                short: "Visibility",
                desc: "Not only do you know where your shipments always are, we report the data for actionable insights. Be in the now.",
                image: require('../imgs/visibility.png'),
                alt: "Visibility Icon"
            },
            {
                id: 4,
                title: "Optimization",
                short: "Optimiziation",
                desc: "Get closer looks into your network to unlock optimization opportunities to eliminate redundacies and save money.",
                image: require('../imgs/optimize.png'),
                alt: "Optimize Icon"
            },
            {
                id: 5,
                title: "Expedited Solutions",
                short: "Expedited",
                desc: "We might not have a DeLorean to help you get it there yesterday, but our agile team provides solutions to get it there now.",
                image: require('../imgs/expedited.png'),
                alt: "Fast Delivery Icon"
            },
        ]
    },
];